.pulsating-circle {
  max-width: 125px;
  max-height: 125px;
  border-radius: 50%;
  background: linear-gradient(90deg, #8bd7f7, #818cf8, #c084fc, #f5b8ff, #9cf1fe);
  background-size: 200% 200%;
  animation: colorChange 10s infinite;
  box-shadow: 0 0 15px 5px rgba(139, 215, 247, 0.6),
              0 0 30px 10px rgba(129, 140, 248, 0.5),
              0 0 45px 15px rgba(192, 132, 252, 0.4),
              0 0 60px 20px rgba(245, 184, 255, 0.3),
              0 0 75px 25px rgba(156, 241, 254, 0.2);
}

@keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

.paused .pulsating-circle {
  animation-play-state: paused;
}

.running .pulsating-circle {
  animation-play-state: running;
}

/* Media query for devices with a max width of 1200px */
@media (max-width: 1200px) {
  .pulsating-circle {
    box-shadow: 0 0 10px 3px rgba(139, 215, 247, 0.5),
                0 0 20px 7px rgba(129, 140, 248, 0.4),
                0 0 30px 11px rgba(192, 132, 252, 0.3),
                0 0 40px 15px rgba(245, 184, 255, 0.2),
                0 0 50px 19px rgba(156, 241, 254, 0.1);
  }
}

/* Media query for devices with a max width of 992px */
@media (max-width: 992px) {
  .pulsating-circle {
    box-shadow: 0 0 8px 2px rgba(139, 215, 247, 0.4),
                0 0 16px 6px rgba(129, 140, 248, 0.3),
                0 0 24px 10px rgba(192, 132, 252, 0.2),
                0 0 32px 14px rgba(245, 184, 255, 0.1),
                0 0 40px 18px rgba(156, 241, 254, 0.05);
  }
}

/* Media query for devices with a max width of 768px */
@media (max-width: 768px) {
  .pulsating-circle {
    box-shadow: 0 0 6px 1px rgba(139, 215, 247, 0.3),
                0 0 12px 5px rgba(129, 140, 248, 0.2),
                0 0 18px 9px rgba(192, 132, 252, 0.1),
                0 0 24px 13px rgba(245, 184, 255, 0.05),
                0 0 30px 17px rgba(156, 241, 254, 0.025);
  }
}

