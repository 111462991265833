:root {
  --aurora-gradient: linear-gradient(
    90deg,
    rgba(95, 14, 157, 0.5),
    rgba(182, 255, 255, 0.5),
    rgba(255, 121, 218, 0.5),
    rgba(255, 184, 184, 0.5),
    rgba(186, 174, 255, 0.5)
  );
  --wavy-colors: #8bd7f7, #818cf8, #c084fc, #f5b8ff, #9cf1fe;
  --wavy-wave-width: 5px;
  --wavy-background-fill: rgb(255, 255, 255);
  --wavy-blur: 5px;
  --wavy-speed: slow;
  --wavy-opacity: 0.5;
   --dot-background-color: rgba(0, 0, 0, 0.1);
}

.highlight {
  background-repeat: no-repeat;
  background-position: left center;
  display: inline;
  padding-bottom: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 0.5rem;
  background-image: linear-gradient(to right, #a5b4fc, #c4b5fd);
}


/* Add styles for WavyBackground */
.wavy-background {
  --wavy-colors: #8bd7f7, #818cf8, #c084fc, #f5b8ff, #9cf1fe;
  --wavy-wave-width: 5px;
  --wavy-background-fill: rgb(255, 255, 255);
  --wavy-blur: 5px;
  --wavy-speed: slow;
  --wavy-opacity: 0.5;
  position: relative;
  width: 100%;
}

.wavy-background canvas {
  width: 50%;   /* Scale the canvas to fill the container */
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
}


.wavy-background .content {
  position: relative;
  z-index: 10;
}

.App {
  display: flex;
  flex-direction: column;
  overflow: hidden;

}

/* Ensure the font-family is applied to the whole App */
body, .App, .features-section {
  font-family: "Assistant", sans-serif;
}



@keyframes jiggle {
  0%, 100% { transform: rotate(-3deg); }
  50% { transform: rotate(3deg); }
}

.jiggle {
  animation: jiggle 0.2s ease-in-out;
  animation-iteration-count: 2;
}
.hero-section,
.colored-area,
.use-case-section,
.features-section,
.global-reach-section,
.easy-use-section,
.insights-section,
.page-wrapper,
.feedback-section {
  position: relative;
  z-index: 1;
}

.hero-section::before,
.colored-area::before,
.use-case-section::before,
.features-section::before,
.easy-use-section::before,
.global-reach-section::before,
.insights-section::before,
.feedback-section::before,
.page-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: radial-gradient(var(--dot-background-color) 10%, transparent 5%);
  background-size: 15px 15px;
  background-position: 0 0;
  background-repeat: repeat;
}

.easy-use-section,
.insights-section {
  position: relative;
  z-index: 1;
}

.insights-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}


.MuiButton-root.book-demo-button-2 {
  display: none; 
}

.logo-link {
  width: 200px;
}


.logo {
  width: 30%;
  max-width: 200px;

}

h1 {
  font-size: 60px;
  color: #000000;
  font-weight: 700;
}

h2 {
  font-size: 40px;
  color: #625DEC;
  font-weight: 700;
}


p {
  font-size: 24px;
  font-weight: 500;
}

.first-word {
  color: #000000;
  font-weight: bold;
}


.page-wrapper {
  background-color: #F5F8FB;
  position: relative;
  z-index: 2;
  padding: 2.7%;
  display: flex;
  flex-direction: column;
  min-height: 500px;
}
.hero-section {
  position: relative;
  z-index: 2;
  padding: 2.7%;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); */
}

.colored-area {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%; 
  height: 5%; 
  background-color: #F5F8FB;
  clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
  z-index: 2;
}


.nav-bar, .hero-content {
  position: relative;
  z-index: 3;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 3.5%;
}

.nav-bar .button-container {
  margin-left: auto;
  width: 350px;
  display: flex;
  flex-direction: row;
  gap: 10px; /* Space between the buttons */
}


.button-container-hero {
  align-self: center;
  /* width: 400px; */
  display: flex;
  flex-direction: row;
  gap: 10px; /* Space between the buttons */
}

.button-container-bottom {
  align-self: center;
  width: 400px;
  display: flex;
  flex-direction: row;
  gap: 10px; /* Space between the buttons */
}

.MuiDialog-root .MuiDialog-container .MuiPaper-root {
  width: 500px;
  height: 450px;
  background-color: #F5F8FB;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: "Assistant", sans-serif;
  padding: 24px;
}



.MuiDialog-root .MuiDialogContent-root {
  padding: 0;
}

.MuiDialog-root .MuiTextField-root {
  margin-bottom: 16px;
}

.MuiDialog-root .MuiInputBase-root {
  margin-top: 15px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 2px 10px;
  transition: border-color 0.3s;
  font-family: "Assistant", sans-serif;
  font-weight: 600;
}

.MuiDialog-root .MuiInputBase-root:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.MuiDialog-root .MuiInputBase-root.Mui-focused {
  border: 1px solid #000000;
}

.MuiOutlinedInput-notchedOutline {
  display: none;
}

.MuiDialog-root .MuiInputLabel-root {
  margin-top: 10px;
  color: #000000;
  font-family: "Assistant", sans-serif;
  font-size: 16px;
  font-weight: bold;
  left: 0;
  position: absolute;
  top: -22px;
  transform: none;
}

.arrow {
  width: 95px;
  height: 95px;
  align-self:center;
  /* margin-bottom: 7.5%; */
}

.MuiDialog-root .MuiInputLabel-asterisk {
  display: none;
}

.MuiDialog-root .MuiInputBase-root.Mui-focused,
.MuiDialog-root .MuiInputBase-root.Mui-focused .MuiInputLabel-root {
  color: #000000;
}


.MuiDialog-root .MuiInputLabel-root.Mui-focused {
  font-weight: bold;
  color: #000000;
  content: none;
}

.MuiDialog-root .MuiInputLabel-asterisk {
  display: none;
}

.MuiDialog-root .MuiInputBase-root .MuiInput-underline::before,
.MuiDialog-root .MuiInputBase-root .MuiInput-underline::after {
  border-bottom: none;
}


.MuiButton-root.signup-button {
  margin-top: 2%;
  margin-right: 2%;
  font-style: normal;
  font-size: 14px;
  font-family: "Assistant", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  border-radius: 8px;
  border: 2px solid #000000;
  border: none;
  background-color: #f8f8f8;
  background-color: #625DEC;
  
  color: #000000;
  color: #ffffff;
  padding: 10px 20px;
  min-width: 120px;
  transition: all 0.3s;
}

.MuiButton-root.signup-button:hover {
  
  /* color: #ffffff; */
  background-color: #625DEC;
  box-shadow: 4px 4px 0px black;
}

.MuiButton-root.signup-button:active {
  transform: translateY(2px);
  box-shadow: 2px 2px 0px black;
}

.MuiButton-root.signup-button.Mui-disabled {
  background-color: transparent;
  color: #9b9b9b;
  border-color: #9b9b9b;

}


.MuiButton-root.try-demo-button {
  margin-top: 2%;
  margin-right: 2%;
  font-style: normal;
  font-size: 14px;
  font-family: "Assistant", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  border-radius: 8px;
  border: 2px solid #000000;
  background-color: #f8f8f8;
  
  color: #000000;
  padding: 10px 20px;
  min-width: 120px;
  transition: all 0.3s;
}

.MuiButton-root.try-demo-button:hover {
  background-color: #ffffff; 
  /* color: #ffffff; */
  box-shadow: 4px 4px 0px black;
}

.MuiButton-root.try-demo-button :active {
  transform: translateY(2px);
  box-shadow: 2px 2px 0px black;
}

.MuiButton-root.try-demo-button.Mui-disabled {
  background-color: transparent;
  color: #9b9b9b;
  border-color: #9b9b9b;

}

.MuiButton-root.signup-button-login {
  margin-top: 2%;
  margin-right: 2%;
  font-style: normal;
  font-size: 14px;
  font-family: "Assistant", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  border-radius: 8px;
  border: 2px solid #000000;
  background-color: #f8f8f8;
  color: #000000;
  padding: 10px 20px;
  /* box-shadow: 2px 2px 0px black; */
  transition: all 0.3s;
}

.MuiButton-root.signup-button-login:hover {
  background-color: #ffffff; 
  /* color: #ffffff; */
  box-shadow: 4px 4px 0px black;
}

.MuiButton-root.signup-button-login:active {
  transform: translateY(2px);
  box-shadow: 2px 2px 0px black;
}

.MuiButton-root.signup-button-login.Mui-disabled {
  background-color: transparent;
  color: #9b9b9b;
  border-color: #9b9b9b;

}


.MuiButton-root.signup-button_2 {
  margin-top: 5%;
  font-style: normal;
  font-size: 14px;
  font-family: "Assistant", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  border-radius: 8px;
  border: 2px solid #000000;
  background-color: #f8f8f8;
  color: #000000;
  padding: 10px 20px;
  /* box-shadow: 2px 2px 0px black; */
  transition: all 0.3s;
  margin-bottom: 2%;
}

.nav-bar .signup-button {
  margin-right: 20px; 
}

.MuiButton-root.signup-button_2:hover {
  background-color: #ffffff;
  /* color: #ffffff; */
  box-shadow: 4px 4px 0px black;
}

.MuiButton-root.signup-button_2:active {
  transform: translateY(2px);
  box-shadow: 2px 2px 0px black;
}

.MuiButton-root.book-demo-button {
  margin-top: 2%;
  font-style: normal;
  font-size: 14px;
  font-family: "Assistant", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  border-radius: 8px;
  border: 2px solid #000000;
  background-color: #f8f8f8;
  color: #000000;
  padding: 10px 20px;
  transition: all 0.3s;
  min-width: 130px;
}

.MuiButton-root.book-demo-button:hover {
  background-color: #ffffff; 
  box-shadow: 4px 4px 0px black;
}

.MuiButton-root.book-demo-button:active {
  transform: translateY(2px);
  box-shadow: 2px 2px 0px black;
}

.MuiButton-root.book-demo-button.Mui-disabled {
  background-color: transparent;
  color: #9b9b9b;
  border-color: #9b9b9b;
}



.hero-content {
  padding-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
   text-align: center;
}

.hero-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
  /* max-width: 550px; */
}

.use-case-section {
  padding: 10%;
  text-align: center;
  background-color: #F5F8FB;
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); */
}

.use-case-title {
  font-size: 40px;
  color: #000000;
  margin-bottom: 40px;
}

.use-case-boxes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.use-case-box {
  background: #fff;
  border: 2px solid #ddd;
  border-radius: 20px;
  padding: 20px;
  width: 300px;
  height: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  position: relative;
   display: flex;
     flex-direction: column;
  justify-content: space-between;
}

.use-case-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
}

.use-case-box-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.use-case-box-description {
  font-size: 18px;
  color: #666;
  margin-top:10px;
  margin-bottom: 10px;
  flex-grow: 1;
}
/* //625DEC */


.use-case-button {
  font-family: "Assistant", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #625DEC;
  color: #ffffff;
  border: 1px solid #8b8b8b;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  align-self: center;
  margin-top: auto;
  width: 60%;
}

.use-case-button .live-animation {
  margin-left: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: radial-gradient(circle, #19F72F 50%, rgba(25, 247, 47, 0) 70%);
  animation: pulse-demo 1.5s infinite;
}

@keyframes pulse-demo {
  0% {
    transform: scale(0.9);
    opacity: 1;
    box-shadow: 0 0 0px rgba(25, 247, 47, 0);
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
    box-shadow: 0 0 6px 3px rgba(25, 247, 47, 0.5);
  }
  100% {
    transform: scale(0.9);
    opacity: 1;
    box-shadow: 0 0 0px rgba(25, 247, 47, 0);
  }
}
.paused .live-animation {
  animation-play-state: paused;
}

.running .live-animation {
  animation-play-state: running;
}
.try-now-section {
  margin-top: 2%;
  margin-bottom: 2%;
  /* background-color: white; */
  padding: 6%;
  position: relative;
}



.try-now-content {
  display: flex;
  flex-direction: column;
  
}


.try-now-first-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.try-now-second-row {
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.sized_box_horizontal {
  width: 100px;
}

.sized_box_horizontal_50 {
  width: 50px;
}

.sized_box_horizontal_25 {
  width: 25px;
}


.sized_box_vertical {
  height: 100px;

}

.sized_box_vertical_50 {
  height: 50px;

}


.try-now-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 1s ease-in;
}



/* 625DEC */
/* cdabfb  */

.MuiButton-root.try-now-button {  
  width: 200px;
  height: 80px;
  border-radius: 10px;
  border: 2px solid #000000;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 2px 2px 0px #000000;
  transition: all 0.3s;
  display: flex;
  min-width: 40px;
}

.MuiButton-root.try-now-button:hover {
  background-color: #ffffff;
  color: #ffffff;
  box-shadow: 4px 4px 0px black
}

.MuiButton-root.try-now-button:active {
  transform: translateY(2px);
  box-shadow: 2px 2px 0px black
}

.try-now-button-text {
  margin-top: 20px;
  font-family: "Assistant", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.try-now-button-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.try-now-title {
  color: #000000;
  position: absolute;
  font-size: 55px;
  max-width: 30%;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  margin-bottom: 5%;
}

.try-now-description {
  margin-top: 5%;
  font-size: 30px;
  text-align: center;
}

.testimonial-section {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
}

.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50px;
  width: 500px;
}

.quotes {
  width: 30px;
  height: 30px;
}
.testimonial {
  margin-bottom: 2px;
  text-align: center;
}

.testimonial-text {
  font-size: 20px;
  font-style: italic;
  color: #000;
}
/* Add ease in/ease out animation for the Accordion */
.MuiAccordion-root {
  overflow: hidden;
  transition: all 0.5s ease-in-out !important;
}

.MuiAccordionSummary-root {
  transition: all 0.5s ease-in-out !important;
}

.MuiAccordionDetails-root {
  transition: all 0.5s ease-in-out !important;
}

/* Ensure all text in the features section uses the Assistant font */
.features-section,
.features-section .MuiTypography-root {
  font-family: "Assistant", sans-serif;
}


.features-section {
  display: flex;
  flex-direction: row;
  padding: 4% 8%;
  margin-top: 2%;
}

/* Style for the feature numbering */
.feature-number {
  font-size: 24px; /* Adjust size as needed */
  font-weight: 200;
  margin-right: 20px;
  color: #625DEC;
}

.feature-title {
  font-size: 32px !important; 
}

.ai_name {
  color: #8e8e8e;
  font-weight: bold;
  align-self: center;
  font-size: 24px;
}


.features-left {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px;
  width: 50%;
  

}

.features-title {
  font-size: 40px;
  color: #000000;
  margin-bottom: 5px;
  
}

.features-description {
  font-size: 18px;
  color: #666;
  width: 80%;
  margin-bottom: 8%;
  
}

.accordion {
  max-width: 600px; /* Adjust the width as needed */
  margin: 0 auto; /* Center the accordion */
}


/* Styles for the color-changing circle */
/* Styles for the color-changing circle with drop shadow */

.animated-element {
  display: flex;
  justify-content: flex-start; 
  padding-left: 20%;
}



@keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



/* In App.css */

.global-reach-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
  min-height: 600px; /* Adjust as needed */
  background-color: #F5F8FB;
  position: relative;
}
.global-reach-title {
  font-size: 40px;
  color: #333; /* Darker color for better contrast */
  margin-bottom: 20px;
}

.global-reach-description {
  font-size: 24px;
  color: #555; /* Darker color for better contrast */
  margin-bottom: 40px;
}


.global-reach-content {
  display: flex;
  width: 100%;
}

.global-reach-left {
  flex: 1;
  padding: 20px;
  text-align: left;
}

.global-reach-right {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px; 
}

.global-reach-stats {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 2rem;
  justify-items: center;
  align-items: center;
  padding: 20px;
  z-index: 2;
}

.stat-item {
  text-align: center;
}

.stat-number {
  font-size: 40px; /* Adjust size as needed */
  font-weight: bold;
}

.stat-text {
  font-size: 20px; /* Adjust size as needed */
}



.world-map-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.world-map-svg {
  width: 100%;
  height: 100%;
}
.country-path {
  fill: #eaeaea;  /* Light grey color for countries */
  stroke: #eaeaea;   /* Slightly darker grey stroke */
  transition: fill 0.5s ease;
}

.country-path.highlight {
  fill: #e8d4fd;  /* Highlight color (darker grey) */
}




  .easy-use-section {
    padding: 10%;
    position: relative;
    display: flex;
    flex-direction: row;
  }


.easy-visual {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}


.easy-gif {
  position: relative;
  z-index: 1;
}

.easy-text-points {
  max-width: 65%;

}

.easy-text-title {
  max-width: 75%;
  color: #000;
}

.easy-text {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.insights-section {
  background-color: #F5F8FB;
  padding: 10%;
  position: relative;
  display: flex;
  flex-direction: row;

}

  .insights-visual {
    width: 50%;
    align-items: start;
  }

.insights-text-title {
  color: #000000;
  max-width: 75%;
}

.insights-text {
  display: flex;
  flex-direction: column;
  width: 70%;

}

.insights-text-points {
  max-width: 65%;
}

.first-word-insights {
  color: #ffffff;
  font-weight: bold;
}


.video-container {
  width: 100%;
  border: 2px solid #625DEC;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-right: 0;
  margin-left: 0;
}


.copyright {
  font-size: 12px;
}

.contact {
  font-size: 12px;
  padding-left: 5%;

}

.feedback-section {
  padding: 5%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
}


.feedback-title {
  color: #000000;
  text-align: center;
}




.MuiButton-root.hotline-button {
  width: 180px;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #000000;
  background-color: #FFFFFF;
  padding: 10px;
  box-shadow: 4px 4px 0px #19F72F;
  transition: all 0.3s;
  box-sizing: border-box;
  min-width: 40px;

}

.MuiButton-root.hotline-button:hover {
  background-color: #ffffff;
  color: #ffffff;
  box-shadow: 4px 4px 0px #000000;
}

.MuiButton-root.hotline-button:active {
  transform: translateY(2px);
  box-shadow: 2px 2px 0px #19F72F;
}

.hotline-button-image {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.feedback-option-image {
  width: 60px;
  height: 60px;
}

.feedback-option-text {
  font-size: 20px;
}

/* Add these styles for the policy/support pages and footer links */

.policy-page {

  max-width: 800px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 5% 10%;
  font-family: "Assistant", sans-serif;
  color: #000000;
  background: #ffffff;
  line-height: 1.6;
  border-radius: 8px;
}

.policy-page h1 {
  font-size: 42px;
  margin-bottom: 20px;
  /* color: #625DEC; */
}

.policy-page h2 {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 10px;
  color: #000;
}

.policy-page p {
  font-size: 18px;
  margin-bottom: 20px;
}

.footer-container {
  width: 100%;
  background-color: #000000;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.footer-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 10px;
}


.footer-columns {
  display: flex;
  flex-direction: row;
  gap: 60px;
  flex-wrap: wrap;
  justify-content: center; /* Centers the content on wide screens */
}

.footer-column {
  display: flex;
  flex-direction: row;
}

.footer-copyright,
.footer-contact {
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}
.footer-icon {
  color: #ffffff; /* White color for the icons */
  transition: color 0.3s ease;
  text-decoration: none;
}

.footer-heading {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}


.footer-link {
  color: #ffffff;
  font-size: 14px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s ease;
  font-weight: 600;
}


.footer-list {
  display: flex;
  gap: 10px;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-list li {
  margin-bottom: 8px;
}

.footer-link:hover {
  border-bottom: 1px solid #000000;
}

.social-icons {
  display: flex;
  gap: 15px; /* Space between icons */
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-icon {
  color: #ffffff; /* White color for the icons */
  transition: color 0.3s ease;
  text-decoration: none;
}

.footer-icon:hover {
  color: #625DEC; /* Purple color on hover */
}

.footer-icon svg {
  vertical-align: middle;
}




@media (max-width: 1400px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 32px;
  }

  p {
    font-size: 20px;
  }

.quotes {
  width: 25px;
  height: 25px;
}

  .feature-title {
  font-size: 28px !important; 
}

.features-left {

  width: 60%;
  

}

.global-reach-title {
  font-size: 38px;
  
}


.global-reach-description {
  font-size: 22px
}

.stat-number {
  font-size: 36px; /* Adjust size as needed */
  font-weight: bold;
}

.stat-text {
  font-size: 18px; /* Adjust size as needed */
}

.features-description {

  margin-bottom: 8%;
  
}


.MuiButton-root.try-now-button {
  width: 175px;
  height: 70px;
}

.MuiButton-root.hotline-button {
  width: 180px;
  height: 60px;
}

.insights-gif {
  position: relative;
  z-index: 1;
  /* transform: translate(02%, 05%) scale(0.5);
  transform-origin: center; */
}

  .try-now-button-image {
    width: 50px;
    height: 50px;
  }



  .try-now-button-text {
    font-size: 18px;
  }

  .arrow {
  width: 90px;
  height: 90px;
  align-self:center;
  /* margin-bottom: 7.5%; */
}

  .feedback-option-image {
    width: 50px;
    height: 50px;
  }

  .try-now-title {
  font-size: 50px;
}

  .feedback-option-text {
    font-size: 18px;
  }

  .try-now-description {
  font-size: 30px;
}


  .hotline-button-image {
    width: 35px;
    height: 35px;
  }

  .MuiButton-root.hotline-button {
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 1025px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
  }

  .feature-title {
  font-size: 28px !important; 
}

.global-reach-title {
  font-size: 36px;
}
.global-reach-description {
  font-size: 20px
}

.stat-number {
  font-size: 34px; /* Adjust size as needed */
  font-weight: bold;
}

.stat-text {
  font-size: 16px; /* Adjust size as needed */
}



.quotes {
  width: 20px;
  height: 20px;
}

  p {
    font-size: 18px;
  }
  .MuiButton-root.try-now-button {
  width: 150px;
  height: 65px; 
}

.MuiButton-root.hotline-button {
  width: 150px;
  height: 60px;
}

.world-map-svg {
  width: 100%;
  height: 100%;
}


.insights-gif {
  position: relative;
  z-index: 1;
  transform: translate(02%, 05%) scale(0.4);
  transform-origin: center;
}

  .try-now-button-image {
    width: 45px;
    height: 45px;
  }

    .arrow {
  width: 80px;
  height: 80px;
  align-self:center;
  /* margin-bottom: 7.5%; */
}

.try-now-title {
  font-size: 50px;
}

.testimonial-text {
  font-size: 14px;
  /* font-style: italic; */
  color: #000;
}

  .try-now-button-text {
    font-size: 16px;
  }

  .feedback-option-image {
    width: 45px;
    height: 45px;
  }

  .feedback-option-text {
    font-size: 16px;
  }

  .hotline-button-image {
    width: 30px;
    height: 30px;
  }


  .easy-use-section,
  .insights-section,
  .feedback-section {
    padding: 60px;
  }

  .easy-text,
  .insights-text,
  .feedback-text {
    width: 80%;
  }

  .easy-text-points,
  .insights-text-points,
  .feedback-paragraph {
    max-width: 80%;
  }

  .try-now-description {
  font-size: 25px;
}


  .easy-text-title,
  .insights-text-title,
  .feedback-title {
    max-width: 90%;
  }

  
}

@media (max-width: 769px) {

    .insights-visual {
    width: 100%;
  }
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 32px;
  }

  .arrow {
  width: 70px;
  height: 70px;
  align-self:center;

  margin-bottom: 5%;
}

.global-reach-title {
  font-size: 34px;
}

.global-reach-description {
  font-size: 18px
}

.nav-bar .book-demo-button {
  padding: 5px 10px;
  display: none; 
}

.nav-bar .button-container {
  width: 160px;
}


.MuiButton-root.book-demo-button-2 {
  display: flex;
  margin-top: 2%;
  font-style: normal;
  font-size: 14px;
  font-family: "Assistant", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  border-radius: 8px;
  border: 2px solid #000000;
  background-color: #f8f8f8;
  color: #000000;
  padding: 5px 10px;
  transition: all 0.3s;
}

.MuiButton-root.book-demo-button-2:hover {
  background-color: #ffffff; 
  box-shadow: 4px 4px 0px black;
}

.MuiButton-root.book-demo-button-2:active {
  transform: translateY(2px);
  box-shadow: 2px 2px 0px black;
}

.MuiButton-root.book-demo-button-2.Mui-disabled {
  background-color: transparent;
  color: #9b9b9b;
  border-color: #9b9b9b;
}

.MuiButton-root.book-demo-button {
  padding: 5px 10px;
  font-size: 10px;
  min-width: 80px;
}


  .global-reach-content {
    flex-direction: column;
  }

    .global-reach-left,
  .global-reach-right {
    flex: none;
    width: 100%;
  }

  .video-container {
    width: 100%;
    border: 2px solid #625DEC;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-right: 0;
    margin-left: 0;
  }

  .button-container-hero {
    display: flex;
    flex-direction: row;
    width: 400px;
    align-self: flex-start;
  }
  
  .button-container-bottom {
    display: flex;
    flex-direction: row;
    width: 400px;
    align-self: flex-start;
    margin-left: 0px;
  }
  

  .global-reach-right {
    margin-top: 20px;
    height: 400px; /* Fixed height for smaller screens */
  }

.stat-number {
  font-size: 32px; /* Adjust size as needed */
  font-weight: bold;
}
  .global-reach-stats {
    position: relative;
    width: 100%;
    height: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: 1rem;
    padding: 10px;
  }

  /* .world-map-background {
    position: relative;
    height: 400px; 
  } */

.stat-text {
  font-size: 16px; /* Adjust size as needed */
}

.world-map-svg {
  width: 100%;
  height: 100%;
}


.quotes {
  width: 10px;
  height: 10px;
}


  p {
    font-size: 20px;
  }

  .hero-image {
    width: 100%;
    height: auto;
    z-index: 1;
  }

  .testimonial-text {
  font-size: 12px;
  /* font-style: italic; */
  color: #000;
}

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    

  }



  .hero-text-content {
    width: 100%;
    text-align: left;
  }

  .hero-visual {
    width: 80%;

  }

  .MuiButton-root.try-now-button {
  width: 120px;
  height: 50px;
}

.MuiButton-root.hotline-button {
  width: 120px;
  height: 50px;
}

.features-section {
  display: flex;
  flex-direction: column;
}
.features-left {
  width: 100%;
}

.feature-number {
  font-size: 16px; /* Adjust size as needed */
  font-weight: 200;
  margin-right: 10px;
  color: #625DEC;
}

.feature-title {
  font-size: 24px !important; 
}

.MuiButton-root.book-demo-button-2 {
font-size: 10px;
}


.features-title {
  font-size:28 px;
  color: #000000;
  margin-bottom: 5px;
}

.features-description {
  font-size: 20px;
  color: #666;
  align-self: flex-start;
  width: 100%;
}


  .try-now-section {
    margin-bottom: 10%;
    
  }

  .easy-use-section {
    display: flex;
    flex-direction: column;
  }

  .insights-section {
    display: flex;
    flex-direction: column;
  }


  .insights-gif {
  position: relative;
  z-index: 1;
    transform: translate(02%, 05%) scale(1);
  transform-origin: center;
}

.page-wrapper {
  padding: 10%;
  padding-left: 4%;
  padding-right: 5%;
}
.hero-section {
  padding: 10%;
}
.try-now-description {
  font-size: 15px;
}

  .easy-text {
    width: 100%;
  }

  .easy-text-points {
    max-width: 100%;
  }

  .easy-text-title {
    max-width: 100%;
  }

  .insights-text-title {
    max-width: 100%;
  }

  .insights-text {
    width: 100%;
  }

  .insights-text-points {
    max-width: 100%;
  }

  .feedback-text {
    width: 100%;
  }

  .feedback-title {
    max-width: 100%;
    text-align:left;
  }

  .feedback-paragraph {
    max-width: 100%;
  }


  .feedback-options {
    margin-top: 5%;
  }

  .try-now-button-image {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }


  .MuiButton-root.signup-button {
    padding: 5px 10px;
    font-size: 10px;
    margin-right: 0;
    margin-left: 0px;
    min-width: 80px;
    
  }

  .MuiButton-root.try-demo-button {
    padding: 5px 10px;
    font-size: 10px;
    margin-right: 0;
    margin-left: auto;
    min-width: 80px;
    
  }

  .button-container-hero .MuiButton-root.signup-button {
    padding: 5px 10px;
    margin-left: 0;
    min-width: 80px;
  }

  .button-container-hero .MuiButton-root.try-demo-button {
    padding: 5px 10px;
    margin-left: 0;
    min-width: 80px;
  }


  .MuiButton-root.signup-button-login {
    padding: 5px 10px;
    font-size: 10px;
    display: none;
  }


  .logo-link {
    width: 80px;
  }
  

  .MuiButton-root.signup-button_2 {
    padding: 5px 10px;
    display: none;
  }

  .sized_box_vertical {
    height: 50px;

  }

  .sized_box_vertical_50 {
    height: 25px;

  }
  
  .use-case-box {
  padding: 20px;
  width: 250px;
  height: 200px;
}

.use-case-box-title {
  font-size: 20px;
  margin-bottom: 5px;
}

.use-case-box-description {
  font-size: 16px;
  margin-bottom: 10px;
  
}
/* //625DEC */
.use-case-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #625DEC;
  color: #ffffff;
  border: 1px solid #8b8b8b;
  border-radius: 16px;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
  align-self: right;
  margin-top: auto;
  font-size: 12px;
  width: 150px;
  align-self: center;
}



  .sized_box_horizontal {
    display: none;
    width: 0px;
  }


  .easy-gif-content {
    width: 100%;
  }


  .insights-gif-content {
    width: 100%;
  }

  .feedback-option-image {
    width: 50px;
    height: 50px;
  }

  .hotline-button-image {
    width: 30px;
    height: 30px;
  }


  .feedback-option-text {
    font-size: 16px;
  }

  .policy-page {
    max-width: 100%; /* Utilize full screen width */
    
    margin: 0; /* Remove outer margin */
    margin-top: 30px;
    padding: 10%; /* Adjust padding for smaller screens */
    font-family: "Assistant", sans-serif;
    color: #000000;
    background: #ffffff;
    line-height: 1.6;
  }

  .policy-page h1 {
    font-size: 24px; /* Reduce size for smaller screens */
    margin-bottom: 15px;
    /* color: #625DEC; */
  }

  .policy-page h2 {
    font-size: 18px; /* Adjust font size */
    margin-top: 30px;
    margin-bottom: 10px;
    color: #000;
  }

  .policy-page h3 {
    font-size: 14px;
  }

  .policy-page p {
    font-size: 12px; /* Slightly smaller for readability */
    margin-bottom: 15px;
  }

  .policy-page li {
    font-size: 12px;
  }

  .footer-columns {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-top {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }


.footer-copyright,
.footer-contact {
  font-size: 9px;

}
.footer-icon svg{
  color: #ffffff; /* White color for the icons */
  transition: color 0.3s ease;
  text-decoration: none;
  
  width: 20px;
}

.footer-heading {
  font-size: 14px;
}


.footer-link {
  
  font-size: 9px;

}

}

@media (max-width: 481px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 32px;
  }

  p {
    font-size: 16px;
  }


  .global-reach-title {
  font-size: 30px;
}

.global-reach-description {
  font-size: 16px
}


  /* .global-reach-stats {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
  } */


  .stat-number {
  font-size: 28px; /* Adjust size as needed */
  font-weight: bold;
}

.stat-text {
  font-size: 14px; /* Adjust size as needed */
}




  .arrow {
  width: 50px;
  height: 50px;
  align-self:center;
  /* margin-bottom: 7.5%; */
}

  .try-now-button-text {
    font-size: 10px;
  }

  .try-now-section {
    margin-bottom: 10%;
  }

  .feedback-option-text {
    font-size: 12px;

  }

  .feedback-options {
    margin-top: 5%;
  }

  .try-now-button-image {
    width: 25px;
    height: 25px;
  }



  .feedback-option-image {
    width: 35px;
    height: 35px;
  }

  .hotline-button-image {
    width: 15px;
    height: 15px;
  }

  .MuiButton-root.hotline-button {
  width: 120px;
  height: 50px;
  padding: 1px !important;
}


.features-title {
  font-size:24 px;
  color: #000000;
  margin-bottom: 5px;
}

.features-description {
  font-size: 16px;
  color: #666;
  align-self: flex-start;
}



.feature-number {
  font-size: 14px; /* Adjust size as needed */
  font-weight: 200;
  margin-right: 8px;
  color: #625DEC;
}

.feature-title {
  font-size: 20px !important; 
    text-align: center;
}

.animated-element {
  display: flex;
  justify-content: flex-start ;
  padding-left: 20%;
  margin-bottom: 10%;
  
}


}